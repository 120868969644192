import { CountUp } from 'countup.js';

const numberCountUp = (element) => {
    const endValue = parseInt(element.textContent, 10);
    const countUp = new CountUp(element, endValue);

    if (!countUp.error) {
        countUp.start();
    } else {
        console.error(countUp.error);
    }
};

const observeElements = () => {
    const numberElements = document.querySelectorAll('.card-numbers-card-number');

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                numberCountUp(entry.target);
                observer.unobserve(entry.target); // Stop observing once the animation starts
            }
        });
    }, {
        threshold: 0.1 // Adjust this value as needed
    });

    numberElements.forEach(element => {
        observer.observe(element);
    });
};

document.addEventListener('DOMContentLoaded', observeElements);
