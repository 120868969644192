const setVars = () => {
    const header = document.querySelector('.header');

    if (!header) return;

    header.addEventListener('transitionend', () => {
        const headerHeight = header.offsetHeight;

        document.documentElement.style.setProperty('--header-height', headerHeight + 'px');
    });
};

window.addEventListener('DOMContentLoaded', setVars);
window.addEventListener('resize', setVars);
