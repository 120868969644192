// CSS
import 'swiper/css';
import 'swiper/css/grid';
import './vendor/fontawesome/css/all.min.css';
import './vendor/fontawesome/css/sharp-regular.min.css';
import './styles/index.scss';

// Vendor Modules
// import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/collapse'; // if included, the accordion wont work properly... no fucking clue why..
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';

// Custom JS
import './js/email_obfuscator';
import './js/swiper';
import './js/navbar';
import './js/form';
import './js/dropdown';
import './js/vars';
import './js/multistep';
import './js/jobs_filter';
import './js/masonry';
import './js/lightbox';
import './js/animations';
import './js/fixed_elements';
import './js/custom_select';
import './js/search';
import './js/read_more';
