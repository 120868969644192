/*
    @template: hero_filter.html.twig
    @dependencies:
        - looks at the current URL and checks if it has a filter query parameter
        - if it does, it decodes the filter query parameter and splits it by comma to get the category IDs
        - it then shows the tags that match the category IDs in the filter tag list in the Frontend
*/

document.addEventListener('DOMContentLoaded', function () {
    const filter = document.querySelector('.js-jobs-filter');

    if (!filter) return;

    const submitBtn = filter.querySelector('.js-submit-button');
    const currentURL = new URL(window.location.href);
    const urlHasFilter = currentURL.searchParams.has('filter');
    const tagList = document.querySelector('.filter-tags');
    const container = document.querySelector('.filter');

    const checkForPreselectedCategories = () => {
        const selectFields = filter.querySelectorAll('select');

        selectFields.forEach((field) => {
            if (field.value) {
                const optionID = field.value;
                const tag = tagList.querySelector(`[data-id="${optionID}"]`);
                const tagCloseBtn = tag.querySelector('.filter-tag-remove');

                tagCloseBtn.classList.add('d-none');
                container.classList.remove('d-none');
                tagList.classList.remove('d-none');
                tag.classList.remove('d-none');
            }
        });
    };

    checkForPreselectedCategories();

    if (urlHasFilter) {
        const tags = tagList.querySelectorAll('.filter-tag');
        const encodedCategoryIds = currentURL.searchParams.get('filter');
        const categoryIds = decodeURIComponent(encodedCategoryIds).split(
            ',' || '%2C'
        );

        tagList.classList.remove('d-none');
        container.classList.remove('d-none');

        // Show tags that match the category IDs that are present in the URL
        tags.forEach((tag) => {
            categoryIds.forEach((id) => {
                if (tag.getAttribute('data-id') === id) {
                    tag.classList.remove('d-none');
                }
            });
        });

        tags.forEach((tag) => {
            tag.addEventListener('click', function (event) {
                event.preventDefault();

                const categoryID = tag.getAttribute('data-id');
                const selectFields = filter.querySelectorAll('select');

                tag.classList.add('d-none');

                selectFields.forEach((select) => {
                    const optionID =
                        select.options[select.selectedIndex].getAttribute(
                            'data-id'
                        );

                    if (optionID === categoryID) {
                        select.value = '';
                    }
                });

                const selectedCategories = [];
                tags.forEach((tag) => {
                    if (!tag.classList.contains('d-none')) {
                        selectedCategories.push({
                            id: tag.getAttribute('data-id'),
                        });
                    }
                });

                handleSubmit(selectedCategories);
            });
        });
    }

    const handleSubmit = async (selectedCategories, searchTerm = '') => {
        searchTerm = searchTerm.trim();

        if (selectedCategories.length || searchTerm.length > 0) {
            const categoryIds = selectedCategories
                .map((category) => category.id)
                .join(',');
            const encodedCategoryIds = encodeURIComponent(categoryIds);
            const encodedSearchTerm = encodeURIComponent(searchTerm);

            let newURL = null;

            if (searchTerm.length > 0 && categoryIds.length > 0) {
                newURL = `${currentURL.origin}${currentURL.pathname}?filter=${encodedCategoryIds}&searchTerm=${encodedSearchTerm}#job-list`;
            } else if (categoryIds.length > 0) {
                newURL = `${currentURL.origin}${currentURL.pathname}?filter=${encodedCategoryIds}#job-list`;
            } else if (searchTerm.length > 0) {
                newURL = `${currentURL.origin}${currentURL.pathname}?searchTerm=${encodedSearchTerm}#job-list`;
            } else {
                newURL = `${currentURL.origin}${currentURL.pathname}`;
            }

            window.location.href = newURL;
        }
    };

    submitBtn.addEventListener('click', function (event) {
        event.preventDefault();
        let selectedCategories = [];
        const selectFields = filter.querySelectorAll('select');
        const searchField = filter.querySelector('#searchterm');

        selectFields.forEach((select) => {
            const optionID =
                select.options[select.selectedIndex].getAttribute('data-id');

            if (select.value && optionID) {
                selectedCategories.push({ id: optionID });
            }
        });

        handleSubmit(selectedCategories, searchField.value);
    });
});
