const { data } = require('autoprefixer');

// Template: job_multistep_filter.html.twig
const multistepJobs = () => {
    const multistepFilter = document.querySelectorAll('.job-multistep-filter');

    if (!multistepFilter.length) return;

    const currentURL = new URL(window.location.href);
    const urlHasFilter = currentURL.searchParams.has('filter');

    multistepFilter.forEach((el) => {
        let currentStep = 0;
        const form = el.querySelector('form');
        const selectedCategories = [];
        const formSteps = Array.from(form.querySelectorAll('.step'));
        const results = document.querySelector('.job-multistep-filter-results');
        const submitButton = document.querySelector('#submit-btn');

        if (urlHasFilter) {
            formSteps.forEach((step) => step.classList.add('d-none'));
            results.classList.remove('d-none');
        }

        const handleRadioClick = (event) => {
            const {
                value,
                dataset: { id },
            } = event.target;
            const stepIndex = selectedCategories.findIndex(
                (category) => category.step === currentStep
            );

            if (stepIndex !== -1) {
                selectedCategories[stepIndex].value = value;
                selectedCategories[stepIndex].id = id;
            } else {
                selectedCategories.push({ step: currentStep, value, id });
            }

            const parentStepItem = event.target.closest('.step-item');

            if (parentStepItem) {
                form.querySelectorAll('.step')
                    [currentStep].querySelectorAll('.step-item')
                    .forEach((item) => item.classList.remove('active'));
                parentStepItem.classList.add('active');
            }

            if (currentStep === formSteps.length - 1) {
                handleSubmitButtonClick(event);
            } else {
                formSteps[currentStep].classList.add('d-none');
                currentStep++;
                if (currentStep < formSteps.length) {
                    formSteps[currentStep].classList.remove('d-none');
                } else {
                    submitButton.classList.remove('d-none');
                }
            }
        };

        const handlePrevButtonClick = () => {
            if (currentStep > 0) {
                formSteps[currentStep].classList.add('d-none');
                currentStep--;
                formSteps[currentStep].classList.remove('d-none');
                submitButton.classList.add('d-none');
            }
        };

        const handleSubmitButtonClick = async (event) => {
            event.preventDefault();
            const categoryIds = selectedCategories
                .map((category) => category.id)
                .join(',');
            const encodedCategoryIds = encodeURIComponent(categoryIds);
            const newURL = `${currentURL.origin}${currentURL.pathname}?filter=${encodedCategoryIds}#form`;

            try {
                const response = await fetch(newURL, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    window.location.href = newURL;
                } else {
                    console.error(
                        'Failed to update URL with filter parameters'
                    );
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        form.addEventListener('click', (event) => {
            if (event.target.matches('input[type="radio"]')) {
                handleRadioClick(event);
            } else if (event.target.matches('.prev-btn')) {
                handlePrevButtonClick();
            } else if (event.target.matches('#submit-btn')) {
                handleSubmitButtonClick(event);
            }
        });
    });
};

// Template: multistep_initiative_application.html.twig
const multistepInitiativeApplication = () => {
    const multistepFilter = document.querySelectorAll(
        '.multistep-initiative-application'
    );

    if (!multistepFilter.length) return;

    multistepFilter.forEach((el) => {
        let currentStep = 0;
        const form = el.querySelector('form');
        const selectedCategories = [];
        const formSteps = Array.from(form.querySelectorAll('.step'));
        const results = document.querySelector(
            '.multistep-initiative-application-results'
        );
        const stepsWrapper = document.querySelector(
            '.multistep-initiative-application-steps'
        );
        const submitButton = document.querySelector('#submit-btn');

        const handleRadioClick = (event) => {
            const {
                value,
                dataset: { id },
            } = event.target;
            const stepName = formSteps[currentStep].dataset.name;
            const stepIndex = selectedCategories.findIndex(
                (category) => category.step === currentStep
            );

            if (stepIndex !== -1) {
                selectedCategories[stepIndex].value = value;
                selectedCategories[stepIndex].id = id;
                selectedCategories[stepIndex].stepName = stepName;
            } else {
                selectedCategories.push({
                    step: currentStep,
                    value,
                    id,
                    stepName,
                });
            }

            const parentStepItem = event.target.closest('.step-item');

            if (parentStepItem) {
                form.querySelectorAll('.step')
                    [currentStep].querySelectorAll('.step-item')
                    .forEach((item) => item.classList.remove('active'));
                parentStepItem.classList.add('active');
            }

            if (currentStep === formSteps.length - 1) {
                handleSubmitButtonClick(event);
            } else {
                formSteps[currentStep].classList.add('d-none');
                currentStep++;
                if (currentStep < formSteps.length) {
                    formSteps[currentStep].classList.remove('d-none');
                } else {
                    submitButton.classList.remove('d-none');
                }
            }
        };

        const handlePrevButtonClick = () => {
            if (currentStep > 0) {
                formSteps[currentStep].classList.add('d-none');
                currentStep--;
                formSteps[currentStep].classList.remove('d-none');
                submitButton.classList.add('d-none');
            }
        };

        const handleSubmitButtonClick = (event) => {
            event.preventDefault();
            const secondForm = document
                .querySelector('.results-form')
                .querySelector('form');
            const labels = secondForm.querySelectorAll('label');

            selectedCategories.forEach((category) => {
                const dataField = document
                    .querySelector('.results-form-data')
                    .querySelector(`.${category.stepName}`);

                if (dataField) {
                    dataField.textContent = category.value;
                } else {
                    console.error(
                        `Element with class ${category.stepName} not found.`
                    );
                }

                labels.forEach((label) => {
                    const labelText = label.textContent.trim();

                    if (labelText === category.stepName) {
                        const labelFor = label.getAttribute('for');
                        const inputField = secondForm.querySelector(
                            `#${labelFor}`
                        );
                        inputField.value = category.value;
                    }
                });
            });

            results.classList.remove('d-none');
            stepsWrapper.classList.add('d-none');
        };

        form.addEventListener('click', (event) => {
            if (event.target.matches('input[type="radio"]')) {
                handleRadioClick(event);
            } else if (event.target.matches('.prev-btn')) {
                handlePrevButtonClick();
            } else if (event.target.matches('#submit-btn')) {
                handleSubmitButtonClick(event);
            }
        });
    });
};

// Template: multistep_initiative_application.html.twig
const multistepForm = () => {
    const formWrapper = document.querySelector('.results-form');

    if (!formWrapper) return;

    let currentStep = 0;
    const steps = document.querySelectorAll('.wrapper-start');
    const form = formWrapper.querySelector('form');
    const submitContainer = form.querySelector('.submit');
    // const submitButton = document.getElementById('dynamic_form3_submit');
    const submitButton = document.querySelector('button[type="submit"]');

    function showStep(step) {
        steps.forEach((stepElement, index) => {
            stepElement.classList.toggle('d-none', index !== step);
        });
        updateButtons();
    }

    function updateButtons() {
        const existingButtons = submitContainer.querySelectorAll(
            '.btn:not(button[type="submit"])'
        );
        existingButtons.forEach((button) => button.remove());

        if (currentStep > 0) {
            const prevButton = document.createElement('button');
            prevButton.type = 'button';
            prevButton.classList.add('btn', 'btn-white-primary', 'btn-prev');
            prevButton.textContent = 'zurück';
            prevButton.addEventListener('click', () => {
                currentStep--;
                showStep(currentStep);
            });
            submitContainer.insertBefore(prevButton, submitButton);
        }

        if (currentStep < steps.length - 1) {
            const nextButton = document.createElement('button');
            nextButton.type = 'button';
            nextButton.classList.add('btn', 'btn-white-primary', 'btn-next');
            nextButton.textContent = 'weiter';
            nextButton.addEventListener('click', () => {
                if (validateStep(currentStep)) {
                    currentStep++;
                    showStep(currentStep);
                }
            });
            submitContainer.insertBefore(nextButton, submitButton);
            submitButton.classList.add('d-none');
        } else {
            submitButton.classList.remove('d-none');
        }
    }

    function validateStep(step) {
        const inputs = steps[step].querySelectorAll(
            'input[required], textarea[required], select[required]'
        );
        for (let input of inputs) {
            if (!input.value) {
                input.focus();
                return false;
            }
        }
        return true;
    }

    showStep(currentStep);
};

// delte maybe and make it clean - not used right now
const multistepFormJobs = () => {
    const el = document.querySelector('.multistep-form');

    if (!el) return;

    let currentStep = 0;

    const steps = document.querySelectorAll('.wrapper-start');
    const form = el.querySelector('form');
    const submitContainer = form.querySelector('.submit');
    const submitButton = document.querySelector('button[type="submit"]');

    function showStep(step) {
        steps.forEach((stepElement, index) => {
            if (index !== step) {
                stepElement.style.display = 'none';
            } else {
                stepElement.style.display = 'flex';
            }
        });
        updateButtons();
    }

    function updateButtons() {
        const existingButtons = submitContainer.querySelectorAll(
            '.btn:not(button[type="submit"])'
        );

        existingButtons.forEach((button) => button.remove());

        if (currentStep > 0) {
            const prevButton = document.createElement('button');
            prevButton.type = 'button';
            prevButton.classList.add('btn', 'btn-white-primary', 'btn-prev');
            prevButton.textContent = 'zurück';
            prevButton.addEventListener('click', () => {
                currentStep--;
                showStep(currentStep);
            });
            submitContainer.insertBefore(prevButton, submitButton);
        }

        if (currentStep < steps.length - 1) {
            const nextButton = document.createElement('button');
            nextButton.type = 'button';
            nextButton.classList.add('btn', 'btn-white-primary', 'btn-next');
            nextButton.textContent = 'weiter';
            nextButton.addEventListener('click', () => {
                if (validateStep(currentStep)) {
                    currentStep++;
                    showStep(currentStep);
                }
            });
            submitContainer.insertBefore(nextButton, submitButton);
            submitButton.style.display = 'none';
        } else {
            submitButton.style.display = 'block';
        }
    }

    function validateStep(step) {
        const inputs = steps[step].querySelectorAll(
            'input[required], textarea[required], select[required]'
        );

        for (let input of inputs) {
            if (!input.value) {
                input.focus();
                return false;
            }
        }
        return true;
    }

    showStep(currentStep);
};

document.addEventListener('DOMContentLoaded', () => {
    multistepFormJobs();
    multistepForm();
    multistepJobs();
    multistepInitiativeApplication();
});
