const fixedBanner = () => {
    const fixedBanner = document.querySelector('.fixed-banner');

    if (!fixedBanner) {
        return;
    }

    const computedStyle = window.getComputedStyle(fixedBanner);

    if (computedStyle.position === 'fixed') {
        const fixedBannerHeight = fixedBanner.offsetHeight - 10;
        document.body.style.paddingBottom = fixedBannerHeight + 'px';

    } else {
        document.body.style.paddingBottom = '';
    }
};

window.addEventListener('DOMContentLoaded', fixedBanner);
window.addEventListener('resize', fixedBanner);
