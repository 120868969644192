import * as bootstrap from 'bootstrap';

const offcanvasElementList = document.querySelectorAll('.offcanvas')
const navbarToggler = document.querySelector('.navbar-toggler')
const offcanvasList = [...offcanvasElementList].map(offcanvasEl => new bootstrap.Offcanvas(offcanvasEl))

offcanvasList.forEach(offcanvas => {
    offcanvas._element.addEventListener('shown.bs.offcanvas', () => {
        navbarToggler.classList.add('open')
    })
    offcanvas._element.addEventListener('hidden.bs.offcanvas', () => {
        navbarToggler.classList.remove('open')
    })
});


