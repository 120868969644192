/**
 * Handles the behavior of Bootstrap 5 dropdown menus based on the viewport width or the device type.
 * If the viewport width is less than 768px or the device is a touch device, the dropdown menus open on click instead of hover.
 * The click event adds the class 'show' to the dropdown menu and prevents the default click event.
 * Additionally, the CSS rule that opens the dropdown on hover is removed from the first stylesheet of the document, effectively disabling the hover effect for these conditions.
 * @function dropdownHandler
 * @global
 */

const dropdownHandler = () => {
    window.addEventListener('DOMContentLoaded', () => {
        const dropdowns = document.querySelectorAll('.dropdown-toggle');
        const styleSheet = document.styleSheets[0];
        const rules = styleSheet.cssRules || styleSheet.rules;

        dropdowns.forEach(dropdown => {
            dropdown.addEventListener('click', function (event) {
                event.preventDefault();
                this.parentElement.querySelector('.dropdown-menu').classList.toggle('show');
            });
        });

        for (let i = 0; i < rules.length; i++) {
            if (rules[i].selectorText === '.dropdown:hover .dropdown-menu') {
                styleSheet.deleteRule(i);
                break;
            }
        };
    });
};

dropdownHandler();
