document.addEventListener('DOMContentLoaded', function () {
    const customSelects = document.querySelectorAll('.custom-select');

    customSelects.forEach(customSelect => {
        const customSelectTrigger = customSelect.querySelector('.custom-select-trigger');
        const customOptions = customSelect.querySelector('.custom-options');
        const customOptionElements = customOptions.querySelectorAll('.custom-option');
        const hiddenSelect = customSelect.nextElementSibling;

        customSelectTrigger.addEventListener('click', function () {
            customOptions.style.display = customOptions.style.display === 'block' ? 'none' : 'block';
        });

        customOptionElements.forEach(option => {
            option.addEventListener('click', function () {
                customSelectTrigger.textContent = this.textContent;
                hiddenSelect.value = this.getAttribute('data-value');
                customOptions.style.display = 'none';
            });
        });

        document.addEventListener('click', function (e) {
            if (!customSelect.contains(e.target)) {
                customOptions.style.display = 'none';
            }
        });
    });
});
