import anime from '../vendor/anime/anime.min.js';

const masonry = () => {
    const tradeList = document.querySelector('.js-item-list');
    let isTradeAnimationStarted = false;

    if (!tradeList) return;

    function startTradeAnimation() {
        isTradeAnimationStarted = true;
        tradeList.style.opacity = '1';

        const animeSettings = {
            duration: 800
        };
        const tradeItems = Array.from(tradeList.querySelectorAll('.item-inner'));
        const tradeItemsPaths = [];

        // Create stroke svg around each item
        tradeItems.forEach(item => {
            const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            const itemW = item.offsetWidth;
            const itemH = item.offsetHeight;

            item.classList.add('anime-item');
            svg.setAttribute('fill', 'none');
            svg.setAttribute('width', `${itemW}px`);
            svg.setAttribute('height', `${itemH}px`);
            svg.setAttribute('viewBox', `0 0 ${itemW} ${itemH}`);
            path.setAttribute('d', `M0,0 l${itemW},0 0,${itemH} -${itemW},0 0,-${itemH}`);
            path.setAttribute('stroke-dashoffset', anime.setDashoffset(path));
            path.setAttribute('stroke', '#888888');
            path.setAttribute('stroke-width', '2px');
            svg.appendChild(path);
            tradeItemsPaths.push(path);
            item.parentNode.appendChild(svg);
        });

        // Animate homepage references
        anime({
            targets: tradeItems,
            duration: animeSettings.duration / 2,
            delay: (t, i) => i * 150 + animeSettings.duration,
            easing: 'linear',
            opacity: [0, 1],
            scale: [0.5, 1]
        });

        // Animate homepage references stroke svgs
        anime({
            targets: tradeItemsPaths,
            duration: animeSettings.duration,
            delay: (t, i) => i * 150,
            easing: 'easeInOutSine',
            opacity: [
                { value: [0, 1] },
                {
                    value: [1, 0],
                    delay: 500,
                    duration: animeSettings.duration / 2,
                    easing: 'linear'
                }
            ],
            strokeDashoffset: [anime.setDashoffset, 0]
        });
    }

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    if (tradeList) {
        if (isInViewport(tradeList)) {
            startTradeAnimation();
        }

        window.addEventListener('resize', () => {
            if (!isTradeAnimationStarted && isInViewport(tradeList)) {
                startTradeAnimation();
            }
        });

        window.addEventListener('scroll', () => {
            if (!isTradeAnimationStarted && isInViewport(tradeList)) {
                startTradeAnimation();
            }
        });
    }
};

window.addEventListener('DOMContentLoaded', () => { masonry(); });
