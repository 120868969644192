import Swiper from 'swiper';
import { Navigation, Grid } from 'swiper/modules';

const initSwiper = () => {
    const swiper = document.querySelectorAll('.swiper');

    if (swiper.length === 0) {
        return;
    }

    swiper.forEach((swiper) => {
        const data = swiper.dataset.swiper;
        const options = JSON.parse(data);

        new Swiper(swiper, {
            ...options,
            modules: [Navigation, Grid],
        });
    });
}

window.addEventListener('DOMContentLoaded', () => { initSwiper(); });
