const readMore = () => {
    const elements = document.querySelectorAll('.read-more');

    if (!elements.length) return;

    const handleReadMoreClick = (element, readMoreBtn, initialPaddingBottom) => {
        if (element.classList.contains('open')) {
            element.classList.remove('open');
            readMoreBtn.textContent = 'Weiterlesen';
            element.style.paddingBottom = initialPaddingBottom;

        } else {
            element.classList.add('open');
            readMoreBtn.textContent = 'weniger anzeigen';
            element.style.paddingBottom = initialPaddingBottom + readMoreBtn.offsetHeight + 'px';
        }
    };


    elements.forEach(element => {
        const readMoreBtn = element.querySelector('.read-more-btn');
        const initialPaddingBottom = element.style.paddingBottom;

        readMoreBtn.addEventListener('click', () => handleReadMoreClick(element, readMoreBtn, initialPaddingBottom));
        readMoreBtn.addEventListener('touch', () => handleReadMoreClick(element, readMoreBtn, initialPaddingBottom));
    });
}

document.addEventListener('DOMContentLoaded', readMore);
