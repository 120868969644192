const searchNews = () => {
    const toggle = document.querySelector('.search-term-toggle');

    if (!toggle) return;

    const searchField = document.querySelector('.search-term-input-group');
    const submitButton = document.querySelector('.search-term-submit');
    const inputField = document.querySelector('.search-term-input');
    const currentURL = new URL(window.location.href);

    const toggleSearchField = () => {
        searchField.classList.toggle('d-sm-none');
        inputField.focus();
    };

    const handleSearch = async () => {
        const searchTerm = inputField.value;
        const encodedSearchTerm = encodeURIComponent(searchTerm);
        const searchURL = `${currentURL.origin}${currentURL.pathname}?searchTerm=${encodedSearchTerm}#news-list`;

        window.location.href = searchURL;
    };

    const setSearchTermFromURL = () => {
        const params = new URLSearchParams(currentURL.search);
        const searchTerm = params.get('searchTerm');
        if (searchTerm) {
            inputField.value = decodeURIComponent(searchTerm);
        }
    };

    toggle.addEventListener('click', toggleSearchField);
    submitButton.addEventListener('click', handleSearch);
    inputField.addEventListener('keyup', (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    });

    setSearchTermFromURL();
};

const searchBar = () => {
    const searchBar = document.querySelector('.search-bar');

    if (!searchBar) return;

    const submitButton = searchBar.querySelector('.btn');
    const inputField = searchBar.querySelector('.form-control');

    const handleSearch = async () => {
        const searchTerm = inputField.value;
        const targetURL = inputField.getAttribute('data-target');
        const encodedSearchTerm = encodeURIComponent(searchTerm);

        // Extract the anchor part if it exists
        const [baseURL, anchor] = targetURL.split('#');
        const searchURL = `${baseURL}?searchTerm=${encodedSearchTerm}${anchor ? `#${anchor}` : ''}`;

        window.location.href = searchURL;
    };

    submitButton.addEventListener('click', handleSearch);
};

document.addEventListener('DOMContentLoaded', () => {
    searchNews();
    searchBar();
});
